<template>
  <div class="schedule-wrapper__schedule --holiday-approve">
    <div class="grid-wrapper">
      <div class="grid-info">
        <span class="grid-heading">

        </span>
        <!-- <div class="grid-actions">
          <a @click.prevent="AddLeave" href="">Add leave</a>
        </div> -->
      </div>
      <table class="user-list table odd-even">
        <thead>
          <tr class="user-list__actions">
            <th class="name">{{ $t('Name') }}</th>
            <th class="reason">{{ $t('Reason') }}</th>
            <th class="start-date">{{ $t('Start date') }}</th>
            <th class="end-date">{{ $t('End date') }}</th>
            <th class="create-date">{{ $t('Created') }}</th>
            <th class="edit"></th>
          </tr>
        </thead>
        <tbody>
          <tr :data-leave="JSON.stringify(leave)" class="user-list__row clickable" @mousedown="ClickOpen" @mousemove="ClickOpen" @mouseup="ClickOpen" :key="index" v-for="(leave, index) in FilteredEvents">
            <td class="name">{{ TitleCase(leave.name) }}</td>
            <td class="reason">{{ leave.reason + (leave.half_day ? ' - ' + $t('half day') : '') }}</td>
            <td class="start-date">{{ DateOnly(leave.from_date) }}</td>
            <td class="end-date">{{ DateOnly(leave.to_date) }}</td>
            <td class="create-date">{{ DateOnly(leave.create_date) }}</td>
            <td @mouseenter="SetBackground" @mouseleave="SetBackground" class="actions">
              <a @click.prevent="" href="" class="icon dots">
                <ul class="item-actions">
                  <li>
                    <a @click.prevent="EditLeave" href="">{{ $t('Edit') }}</a>
                  </li>
                  <li>
                    <a @click.prevent="ApproveLeave" href="" class="success">{{ $t('Approve') }}</a>
                  </li>
                  <li>
                    <a @click.prevent="RejectLeave" href="" class="error">{{ $t('Delete') }}</a>
                  </li>
                </ul>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <Modal modal="edit_leave" :value="modal.edit_leave.open" :title="$t('Edit request')">
      <div id="edit-leave">
        <form @submit.prevent="ApproveLeave" class="form" action="#" autocomplete="off">
          <div class="form-group" id="user">
            <Select name="name" mode="single" readonly="true" :list="user.options" :selected="[user.selected]" />
            <Select name="reason" mode="single" :list="schema" :selected="[leave.reason_id]" style="margin-left: 30px;" />
            <DatePicker name="start date" @date="GetDate" :selected="leave.from_date" />
            <DatePicker name="end date" :DisabledBefore="DisabledBefore" :selected="leave.to_date" style="margin-left: 30px;" />
            <div style="display: flex; width: 100%; padding-left: 20px;">
              <label class="checkbox" for="half-day" style="width: auto; cursor: pointer;">
                <span class="span">{{ $t('Is a half day?') }}</span>
                <input type="checkbox" id="half-day" name="half-day" :checked="leave.half_day">
                <svg viewBox="0 0 21 21">
                  <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                </svg>
              </label>
            </div>
            <label class="full" :class="leave.note !== '<br>' ? 'filled' : ''" for="notes">
              <span :class="leave.note !== '<br>' ? 'show' : ''" class="span">{{ $t('Note') }}</span>
              <textarea rows="1" name="notes" id="notes" @input="ValidateInput" :placeholder="$t('Note')" v-html="leave.note !== '<br>' ? leave.note : ''"></textarea>
            </label>
          </div>
          <div class="form-action">
            <input class="submit done" style="min-width: 115px; margin-right: auto;" type="submit" :value="$t('Approve')">
            <input class="submit done" style="min-width: 115px; margin-right: 20px;" type="submit" @click.prevent="SaveLeave" :value="$t('Save')">
            <input class="submit danger" style="min-width: 115px;" type="submit" @click.prevent="RejectLeave" :value="$t('Delete')">
          </div>
        </form>
      </div>
    </Modal>

    <Messages />
    <ValidatePopup />

    <div class="loading" v-if="loading">
      <div class="loading-element">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>

  </div>
</template>

<script>
  import { FormElementsBehaviour }  from '@/mixins/FormElementsBehaviour';
  import { TableElementsBehaviour } from '@/mixins/TableElementsBehaviour';
  import { Dates }                  from '@/mixins/Dates';
  import { Tool }                   from '@/helpers/Tool';
  import { BPA }                    from '@/helpers/BPA';
  import DatePicker                 from '@/components/snippets/DatePicker';
  import Select                     from '@/components/snippets/Select';
  import Modal                      from '@/components/snippets/Modal';

  export default {
    name: 'ScheduleHolidayApprove',
    mixins: [FormElementsBehaviour, TableElementsBehaviour, BPA, Dates],
    components: {
      DatePicker,
      Select,
      Modal
    },
    data() {
      return {
        loading: false,
        DisabledBefore: null,
        clickables: [],
        cached: {},
        schema: {},
        leave: {},
        user: {
          selected: 0,
          options: {}
        },
        employees: {},
        events: [],
        modal: {
          edit_leave: {
            open: false
          }
        }
      }
    },
    mounted() {
      this.GetSchema();
      this.GetEvents();
      this.$eventHub.$on('CloseModal', (modal_name) => {
        if (this.modal[modal_name]) this.modal[modal_name].open = false;
        if (modal_name == 'edit_leave') {
          this.leave = {};
          this.cached.leave = {};
          BPA.cache.session({name: this.$options.name, set: {leave: {}}});
          for (let i = 0; i < this.clickables.length; i++) {
            if (this.clickables[i].classList.contains('selected')) {
              this.clickables[i].classList.remove('selected');
            }
          }
        }
      });
      this.cached = BPA.cache.session({name: this.$options.name, get: 'leave'});
    },
    computed: {
      FilteredEvents() {
        let leaves = [];
        this.events.map(employee => {
          employee.items.map(leave => {
            let tmp = {};
            tmp.id = leave.id;
            tmp.name = employee.name + ' ' + employee.surname;
            tmp.reason = this.schema[leave.reason_id];
            tmp.reason_id = leave.reason_id;
            tmp.note = leave.note;
            tmp.half_day = leave.half_day;
            tmp.from_date = leave.from_date;
            tmp.to_date = leave.to_date;
            tmp.create_date = leave.create_date;
            leaves.push(tmp);
          });
        })
        return leaves;
      },
      locale() {
        return this.$i18n.locale;
      }
    },
    watch: {
      locale() {
        this.GetSchema();
      }
    },
    methods: {
      TitleCase(string, split) {
        return Tool.TitleCase(string, split);
      },
      DateOnly(date) {
        date = date + ' 00:00:00';
        date = Tool.DateFormat(date).replace(Tool.TimeOnly(date), '');
        return date.replace(/,(?=[^,]*$)/, '');
      },
      GetSchema() {
        let schema = BPA.api.ScheduleReasons('GET');
        for (let i in schema) schema[i] = this.$t(schema[i]);
        this.schema = schema;
      },
      async UpdateLeave(request) {
        await BPA.api.UpdateLeave(request).then(response => {
          return BPA.api.response({response});
        }).then(response => {
          if (!response.ok) return;
          this.GetEvents();
          this.$eventHub.$emit('ShowMessages', {
            message: 'Leave updated',
            type: 'success',
            hide: 2000
          });
        }).catch(e => e);
      },
      async GetEvents() {
        await BPA.api.GetCompanyFutureLeave().then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          if (!response.ok) return;
          this.events = response.result || [];
          this.$nextTick().then(() => {
            this.clickables = document.querySelectorAll('.clickable');
            if (this.cached.leave && Object.keys(this.cached.leave).length) {
              for (let i = 0; i < this.clickables.length; i++) {
                if (JSON.parse(this.clickables[i].dataset.leave).id == this.cached.leave.id) {
                  this.EditLeave({target: this.clickables[i]});
                  break;
                }
              }
            }
          });
        }).catch(e => e);
      },
      AddLeave() {
        this.AddLeaveModal = true;
      },
      GetDate(date) {
        this.DisabledBefore = date;
      },
      async ApproveLeave(event) {
        let leave_id = '';
        let element = event.target;
        let row = element.localName == 'tr' ? element : element.closest('tr');
        const leave_data = {approve: true};
        if (row) {
          leave_id = JSON.parse(row.closest('tr').dataset.leave).id;
        } else {
          leave_id = this.leave.id;
        }
        await this.UpdateLeave({leave_id: leave_id, leave_data: leave_data});
      },
      EditLeave(event) {
        let element = event.target;
        let row = element.localName == 'tr' ? element : element.closest('tr');
        this.leave = JSON.parse(row.dataset.leave);
        for (let i = 0; i < this.clickables.length; i++) {
          if (JSON.parse(this.clickables[i].dataset.leave).id == this.leave.id) {
            this.clickables[i].classList.add('selected');
            if (this.cached.leave && Object.keys(this.cached.leave).length) {
              this.clickables[i].scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'center'
              });
            }
            break;
          }
        }
        BPA.cache.session({name: this.$options.name, set: {leave: this.leave}});
        this.user.options[0] = element?.querySelector('.name')?.textContent;
        this.user.selected = 0;
        this.modal.edit_leave.open = true;
      },
      async RejectLeave(event) {
        let leave_id;
        if (event.target.closest('tr')) {
          leave_id = JSON.parse(event.target.closest('tr').dataset.leave).id;
        } else {
          leave_id = this.leave.id;
        }
        await this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, reject leave',
          disapprove: 'No',
          type: 'danger'
        });
        this.$eventHub.$on('ValidateModalStop', approve => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          BPA.api.RejectLeave(leave_id).then(response => {
            return BPA.api.response({response});
          }).then(response => {
            if (!response.ok) return;
            this.GetEvents();
            this.modal.edit_leave.open = false;
            this.$eventHub.$emit('ShowMessages', {
              message: 'Leave deleted',
              type: 'success'
            });
          }).catch(e => e);
        });
      },
      async SaveLeave(event) {
        let state = this.ValidateForm(event.target.closest('form'));
        if (state.flag && Object.keys(state.leave).length > 0) {
          await this.UpdateLeave({leave_id: this.leave.id, leave_data: state.leave});
        } else {
          this.$eventHub.$emit('ShowMessages', {
            message: 'No changes made',
            type: 'warning',
            hide: 2000
          });
        }
      },
      ValidateForm(form) {
        let updated = {},
          flag = true;

        let StartDateInput = form.querySelector('input[name="start date"]');
        if (StartDateInput.value !== '') {
          let date = new Date(parseInt(StartDateInput.value));
          date = date.getFullYear() + '-' + Dates.FormatDoubleDigits(date.getMonth() + 1) + '-' + Dates.FormatDoubleDigits(date.getDate());
          if (date !== this.leave.from_date) {
            updated.from_date = date;
          }
        } else {
          this.Error(
            StartDateInput,
            'Start date must be set'
          );
          flag = false;
        }

        let EndDateInput = form.querySelector('input[name="end date"]');
        if (EndDateInput.value !== '') {
          let date = new Date(parseInt(EndDateInput.value));
          date = date.getFullYear() + '-' + Dates.FormatDoubleDigits(date.getMonth() + 1) + '-' + Dates.FormatDoubleDigits(date.getDate());
          if (date !== this.leave.to_date) {
            updated.to_date = date;
          }
        } else {
          this.Error(
            EndDateInput,
            'End date must be set'
          );
          flag = false;
        }

        let ReasonInput = form.querySelector('input[name="reason"]');
        if (ReasonInput.value !== '') {
          if (parseInt(ReasonInput.value) !== this.leave.reason_id) {
            updated.reason_id = parseInt(ReasonInput.value);
          }
        } else {
          this.Error(
            ReasonInput,
            'Reason must be set'
          );
          flag = false;
        }

        let HalfDayInput = form.querySelector('input[name="half-day"]');
        if (HalfDayInput.checked !== this.leave.half_day) {
          updated.half_day = HalfDayInput.checked;
        }
        
        let NotesInput = form.querySelector('textarea[name="notes"]');
        if (NotesInput.value !== '') {
          updated.note = NotesInput.value;
        }

        return {
          'flag': flag,
          'leave': updated
        }
      },
      ClickOpen(e) {
        if (!e) return;
        let row = {};
        let elm = e.target;
        if (elm.localName != 'tr') row = elm.closest('tr');
        const filter = ['actions', 'icon dots'];
        const mousedown = (state) => {
          if (state === true || state === false) {
            row.mousedown = state;
          } else {
            return row.mousedown;
          }
        }
        for (let i = 0; i < this.clickables.length; i++) {
          if (this.clickables[i] != row) {
            this.clickables[i].classList.remove('hover', 'selected');
          }
        }
        if (elm.localName == 'a') return;
        if (filter.some(x => new RegExp(x).test(elm.className))) return;
        if (e.type == 'mousedown') {
          mousedown(e.which == 1);
        }
        if (e.type == 'mousemove') {
          mousedown(false);
        }
        if (e.type == 'mouseup') {
          if (mousedown()) {
            const data = JSON.parse(row.dataset.leave);
            const date = new Date(data?.from_date);
            date.setHours(0, 0, 0);
            this.DisabledBefore = date;
            this.EditLeave({target: row});
          }
          mousedown(false);
        }
      }
    }
  }
</script>

<style scoped>
</style>
